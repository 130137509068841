import { createApi } from '@reduxjs/toolkit/query/react';

// Models
import { Tag, TagLabel, SearchParams } from 'src/services/types';
import {
  Account,
  User,
  SettingDefinition,
  Sku,
  AccountImportJob,
  AccountGroup
} from 'src/types/AccountManagementTypes';
import PagedModel from 'src/types/PagedModel';

// Utils
import baseQuery from 'src/services/baseQuery';
import { buildParameters } from 'src/services/utils';

interface GeneralRequest {
  paramsData: SearchParams;
}

interface UserAccountsRequest {
  userId: number;
  paramsData: SearchParams;
}

interface AccountUsersRequest {
  accountId: number;
  paramsData: SearchParams;
}

interface UpdateRolesParams {
  userId: number;
  payload: {
    accountId: number;
    roles: Array<{
      accountId: number;
      id: number;
    }>;
  };
}

interface AddRemoveAccountParams {
  userId: number;
  accountId: number;
}

interface ImportAccountsParams {
  file: File;
  packageId: number | null;
  featureIds?: number[] | null;
}

interface AccountGroupParams {
  accountGroupId: number;
}

interface ChildAccountParams {
  accountId: number;
  pageNumber: number;
  pageSize: number;
}
interface ChildAccountAssociateParams {
  parentAccountId: number;
  childAccountId: number;
}

export const accountManagementService = createApi({
  baseQuery: baseQuery({
    baseUrl: '/platform/account-management/rest'
  }),
  reducerPath: 'accntmgmt',
  tagTypes: [
    Tag.Users,
    Tag.Accounts,
    Tag.AccountGroups,
    Tag.AccountGroupAccounts,
    Tag.SettingDefinitions,
    Tag.SKUs,
    Tag.AccountImportJobs
  ],
  endpoints: builder => ({
    getUsers: builder.query<PagedModel<User>, GeneralRequest>({
      providesTags: [{ id: TagLabel.List, type: Tag.Users }],
      query: ({ paramsData }) => {
        const params = buildParameters(paramsData).toString();
        return {
          method: 'GET',
          url: `/users?${params}`
        };
      }
    }),
    getUserAccounts: builder.query<PagedModel<Account>, UserAccountsRequest>({
      providesTags: [{ id: TagLabel.List, type: Tag.Users }],
      query: ({ userId, paramsData }) => {
        const params = buildParameters(paramsData).toString();
        return {
          method: 'GET',
          url: `/users/${userId}/accounts?${params}`
        };
      }
    }),
    getAccounts: builder.query<PagedModel<Account>, GeneralRequest>({
      providesTags: [{ id: TagLabel.List, type: Tag.Accounts }],
      keepUnusedDataFor: 0,
      query: ({ paramsData }) => {
        const params = buildParameters(paramsData).toString();
        return {
          method: 'GET',
          url: `/accounts?${params}`
        };
      }
    }),
    getAccountUsers: builder.query<PagedModel<User>, AccountUsersRequest>({
      providesTags: [{ id: TagLabel.List, type: Tag.Accounts }],
      query: ({ accountId, paramsData }) => {
        const params = buildParameters(paramsData).toString();
        return {
          method: 'GET',
          url: `/accounts/${accountId}/users?${params}`
        };
      }
    }),
    getAccountImports: builder.query<
      PagedModel<AccountImportJob>,
      GeneralRequest
    >({
      providesTags: [{ id: TagLabel.List, type: Tag.AccountImportJobs }],
      query: ({ paramsData }) => {
        const params = buildParameters(paramsData).toString();
        return {
          method: 'GET',
          url: `/import/accounts?${params}`
        };
      }
    }),
    importAccounts: builder.mutation<null, ImportAccountsParams>({
      query: ({ file, packageId, featureIds }) => {
        const formData = new FormData();
        formData.append('file', file);

        const data = {
          packageId,
          featureIds
        };

        formData.append('data', JSON.stringify(data));

        return {
          method: 'POST',
          url: '/import/accounts',
          body: formData
        };
      }
    }),
    updateUserRoles: builder.mutation<User, UpdateRolesParams>({
      invalidatesTags: [{ id: TagLabel.List, type: Tag.Users }],
      query: ({ userId, payload }) => {
        return {
          method: 'PUT',
          body: payload,
          url: `/users/${userId}/roles`
        };
      }
    }),
    addAccountToUser: builder.mutation<null, AddRemoveAccountParams>({
      invalidatesTags: [
        { id: TagLabel.List, type: Tag.Users },
        { id: TagLabel.List, type: Tag.Accounts }
      ],
      query: ({ userId, accountId }) => {
        return {
          method: 'PUT',
          body: { isDefault: false },
          url: `/accounts/${accountId}/users/${userId}/add`
        };
      }
    }),
    removeUserFromAccount: builder.mutation<null, AddRemoveAccountParams>({
      invalidatesTags: [
        { id: TagLabel.List, type: Tag.Users },
        { id: TagLabel.List, type: Tag.Accounts }
      ],
      query: ({ userId, accountId }) => {
        return {
          method: 'DELETE',
          url: `/accounts/${accountId}/users/${userId}/remove`
        };
      }
    }),
    getSettingDefinitions: builder.query<
      PagedModel<SettingDefinition>,
      GeneralRequest
    >({
      providesTags: [{ id: TagLabel.List, type: Tag.SettingDefinitions }],
      query: ({ paramsData }) => {
        const params = buildParameters(paramsData).toString();
        return {
          method: 'GET',
          url: `/settingdefinitions?${params}`
        };
      }
    }),
    getSKUs: builder.query<PagedModel<Sku>, GeneralRequest>({
      providesTags: [{ id: TagLabel.List, type: Tag.SKUs }],
      query: ({ paramsData }) => {
        const params = buildParameters(paramsData).toString();
        return {
          method: 'GET',
          url: `/skus?${params}`
        };
      }
    }),
    getTopAccountGroup: builder.query<AccountGroup, null>({
      providesTags: [{ id: TagLabel.List, type: Tag.AccountGroups }],
      query: () => {
        return {
          method: 'GET',
          url: `/accountgroups`
        };
      }
    }),
    getAccountGroupChildren: builder.query<AccountGroup[], AccountGroupParams>({
      providesTags: [{ id: TagLabel.List, type: Tag.AccountGroups }],
      query: ({ accountGroupId }) => {
        return {
          method: 'GET',
          url: `/accountgroups/${accountGroupId}/children?pageNumber=0&pageSize=100`
        };
      }
    }),
    getAccountGroup: builder.query<AccountGroup, AccountGroupParams>({
      providesTags: [{ id: TagLabel.List, type: Tag.AccountGroups }],
      query: ({ accountGroupId }) => {
        return {
          method: 'GET',
          url: `/accountgroups/${accountGroupId}`
        };
      }
    }),
    getChildAccounts: builder.query<PagedModel<Account>, ChildAccountParams>({
      providesTags: [{ id: TagLabel.List, type: Tag.AccountGroupAccounts }],
      keepUnusedDataFor: 0,
      query: ({ accountId, pageNumber, pageSize }) => {
        return {
          method: 'GET',
          url: `/accounts/${accountId}/childaccounts?pageNumber=${
            pageNumber > 0 ? pageNumber - 1 : pageNumber
          }&pageSize=${pageSize}`
        };
      }
    }),
    associateChildAccount: builder.mutation<null, ChildAccountAssociateParams>({
      invalidatesTags: [{ id: TagLabel.List, type: Tag.AccountGroupAccounts }],
      query: ({ parentAccountId, childAccountId }) => {
        return {
          method: 'POST',
          url: `/accounts/${parentAccountId}/childaccounts/${childAccountId}`
        };
      }
    }),
    disassociateChildAccount: builder.mutation<
      null,
      ChildAccountAssociateParams
    >({
      invalidatesTags: [{ id: TagLabel.List, type: Tag.AccountGroupAccounts }],
      query: ({ parentAccountId, childAccountId }) => {
        return {
          method: 'DELETE',
          url: `/accounts/${parentAccountId}/childaccounts/${childAccountId}`
        };
      }
    })
  })
});

export const {
  useGetUsersQuery,
  useGetUserAccountsQuery,
  useGetAccountsQuery,
  useGetAccountUsersQuery,
  useImportAccountsMutation,
  useUpdateUserRolesMutation,
  useAddAccountToUserMutation,
  useRemoveUserFromAccountMutation,
  useGetSettingDefinitionsQuery,
  useGetSKUsQuery,
  useGetAccountImportsQuery,
  useGetTopAccountGroupQuery,
  useGetAccountGroupQuery,
  useGetAccountGroupChildrenQuery,
  useGetChildAccountsQuery,
  useAssociateChildAccountMutation,
  useDisassociateChildAccountMutation
} = accountManagementService;

export default accountManagementService;
