import React, { useEffect, useState } from 'react';

// Models
import { Role, User } from 'src/types/AccountManagementTypes';

// Components
import { Form, FormGroup } from 'semantic-ui-react';
import { Dropdown } from '@120wateraudit/envirio-components';
import { Button } from '@120wateraudit/waterworks';
import {
  Device,
  mediaScreen
} from '@120wateraudit/envirio-components/dist/utils';
import styled from 'src/theme';
import { toastError, toastSuccess } from 'src/components/shared';

// Custom Hooks
import { useUpdateUserRolesMutation } from 'src/services';
import { AccountType } from '@120wateraudit/envirio-components/dist/models';

interface RoleOption {
  key: string;
  text: string;
  value: number;
}

const matchRolesToAccount = (accountId: number, user: User) => {
  const matchedRoles: Role[] = [];
  if (user.userRoles && user.userRoles.length > 0) {
    user.userRoles
      .filter(ur => ur.accountId && ur.accountId === accountId)
      .forEach(ur => {
        if (user.roles && user.roles.length > 0) {
          const role = user.roles.find(r => r.id === ur.roleId);
          if (role) {
            matchedRoles.push(role);
          }
        }
      });
  }
  return matchedRoles;
};

const mapRolesToOptions = (roles: Role[], accountType: number) => {
  const roleOptions: RoleOption[] = [];
  roles
    .filter(r =>
      accountType === AccountType.Hydra
        ? r.accountType && r.accountType === -1
        : (r.accountType && r.accountType === accountType) ||
          r.accountType === 0
    )
    .forEach(r => {
      roleOptions.push({
        key: r.name,
        text: r.name,
        value: r.id
      });
    });
  return roleOptions;
};

interface RoleActionWrapperProps {
  accountId: number;
  accountType: number;
  fetchUser: () => void;
  roles: Role[];
  user: User;
  page: number;
}

const RoleActionWrapper = ({
  accountId,
  accountType,
  fetchUser,
  roles,
  user,
  page
}: RoleActionWrapperProps) => {
  const [updated, setUpdated] = useState(false);
  const [accountRoles, setAccountRoles] = useState<Role[]>([]);

  useEffect(() => {
    setAccountRoles(matchRolesToAccount(accountId, user));
  }, [user, page, accountId]);

  const [updateRoles] = useUpdateUserRolesMutation();

  const submitRoleChange = async (userId: number) => {
    const payload = {
      accountId,
      roles: accountRoles.map(cr => ({
        accountId,
        id: cr.id
      }))
    };
    await updateRoles({ userId, payload })
      .unwrap()
      .then(() => toastSuccess('Successfully updated roles.'))
      .catch(() => toastError('Role update failed, please try again.'));
  };

  const onSubmitHandler = async () => {
    await submitRoleChange(user.id);
    setUpdated(false);
    fetchUser();
  };

  const onRoleTypeChanged = (value: number[]) => {
    const selectedRoles = roles.filter(r => value.indexOf(r.id) > -1);
    setAccountRoles(selectedRoles);
    setUpdated(true);
  };

  return (
    <RoleAction>
      <FormStyleWrapper>
        <Form>
          <FormGroup>
            <DropdownWrapper>
              <Dropdown
                multiple
                label="Select Roles"
                options={mapRolesToOptions(roles, accountType)}
                value={accountRoles.map(r => r.id)}
                onChange={(e, d) => onRoleTypeChanged(d.value)}
                style={{ width: '100%' }}
              />
            </DropdownWrapper>
          </FormGroup>
        </Form>
      </FormStyleWrapper>
      <Button
        variant="primary"
        onClick={() => onSubmitHandler()}
        disabled={!updated}>
        Save
      </Button>
    </RoleAction>
  );
};

const DropdownWrapper = styled.div`
  width: 400px;
  ${mediaScreen({
    device: Device.Tablet,
    style: {
      width: '300px'
    }
  })}
  ${mediaScreen({
    device: Device.Mobile,
    style: {
      width: '200px'
    }
  })}
`;

const RoleAction = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const FormStyleWrapper = styled.div`
  margin-right: 20px;
`;

export default RoleActionWrapper;
