import { Role } from '@120wateraudit/envirio-components/dist/models';
import { ApplicationState } from 'src/reducers';

export const getRoles = (state: ApplicationState): Role[] => state.roles.items;

export const getRolesAsSelectList = (state: ApplicationState) =>
  getRoles(state)
    .filter(r => r.name.indexOf('Hydra') === -1)
    .map(r => ({
      key: r.name,
      text: r.name,
      value: r.id
    }));
