import {
  Loader,
  PageHeading,
  TextNavigation,
  colors
} from '@120wateraudit/envirio-components';
import { AccountType } from '@120wateraudit/envirio-components/dist/models';
import * as React from 'react';
import { Query } from 'react-apollo';
import { getToken } from 'src/utils/Token';
import { AWS_BASE } from 'src/utils/API';

import AccountDetails from 'src/components/Accounts/details';
import AccountSkus from 'src/containers/AccountSkus';
import { AccountSoftwarePackages } from 'src/containers/AccountSoftwarePackages/';
import AccountUserInvites from 'src/containers/AccountUserInvites';
import AccountUsers from 'src/containers/AccountUsers';
import { EddTemplateContainer } from 'src/containers/EddTemplate';
import SchoolsAccountReportingContainer from 'src/containers/SchoolsAccountReporting';
import Settings from 'src/containers/Settings/accountSettings';
import { AccountWithTimezone } from 'src/modules/Account/Form';
import { DataTools } from 'src/modules/DataTools';
import { AccountAnalytesContainer } from '../AccountAnalytes';
import PWSAccountReportingContainer from '../PWSAccountReporting/index';
import { ACCOUNT_QUERY } from './data-access';
import { AdminCustomFieldDefinitions } from '@120wateraudit/custom-fields-ui-components';
import { AccountChildAccounts } from 'src/containers/AccountChildAccounts';

const qs = require('query-string');

enum MenuItem {
  Analytes = 'Analytes',
  Details = 'Details',
  Users = 'Users',
  Settings = 'Settings',
  Invites = 'Invites',
  Reporting = 'Reporting',
  SKUs = 'SKUs',
  EDD = 'EDD',
  Packages = 'Packages',
  Tools = 'Tools',
  CustomFields = 'Custom Fields',
  ChildAccounts = 'Child Accounts'
}

interface State {
  activeItem: MenuItem;
  hasDefinitionsRow: boolean;
}

class AccountDetailsContainer extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    let activeItem = MenuItem.Details;
    const query = qs.parse(this.props.location.search);

    if (query && MenuItem[query.tab]) {
      activeItem = query.tab;
    }

    this.state = {
      activeItem,
      hasDefinitionsRow: false
    };
  }

  handleDefinitionsRowChanged = () => {
    this.setState({
      hasDefinitionsRow: !this.state.hasDefinitionsRow
    });
  };

  setActiveMenuItem = (event: any, { name }: { name: MenuItem }) => {
    this.setState({
      activeItem: name
    });
  };

  renderActiveTab({ account }: { account: AccountWithTimezone }) {
    // TODO: convert to <Switch/> so that client state is persisted?
    switch (this.state.activeItem) {
      case MenuItem.Details:
        return <AccountDetails account={account} />;
      case MenuItem.Users:
        return (
          <AccountUsers accountId={account.id} accountName={account.name} />
        );
      case MenuItem.Invites:
        return <AccountUserInvites accountId={account.id} />;
      case MenuItem.Settings:
        return <Settings account={account} accountId={account.id} />;
      case MenuItem.Analytes:
        return <AccountAnalytesContainer accountId={account.id} />;
      case MenuItem.Packages:
        return (
          <AccountSoftwarePackages
            accountId={account.id}
            accountType={account.accountType}
          />
        );
      case MenuItem.Reporting:
        if (account.accountType === AccountType.Schools) {
          return <SchoolsAccountReportingContainer accountId={account.id} />;
        } else if (account.accountType === AccountType.PWS) {
          return <PWSAccountReportingContainer accountId={account.id} />;
        } else {
          return null;
        }
      case MenuItem.SKUs:
        return (
          <AccountSkus accountType={account.accountType} id={account.id} />
        );
      case MenuItem.EDD:
        return (
          <EddTemplateContainer
            accountId={account.id}
            accountType={account.accountType}
            hasDefinitionsRow={this.state.hasDefinitionsRow}
            onDefinitionsRowChanged={this.handleDefinitionsRowChanged}
          />
        );
      case MenuItem.Tools:
        return <DataTools accountId={account.id} />;
      case MenuItem.CustomFields:
        return (
          <AdminCustomFieldDefinitions
            id={account.id}
            idType={'account'}
            accountType={
              account.accountType === 2
                ? 'pws'
                : account.accountType === 3
                ? 'lif'
                : 'stateportal'
            }
            baseUrl={AWS_BASE}
            token={getToken()}
            showReadOnly={true}
            readOnly={false}
          />
        );
      case MenuItem.ChildAccounts:
        return <AccountChildAccounts accountId={account.id} />;
      default:
        return null;
    }
  }

  render() {
    const { id: accountId } = this.props.match.params;
    const { activeItem } = this.state;

    return (
      <Query query={ACCOUNT_QUERY} variables={{ id: parseInt(accountId, 10) }}>
        {({ data, loading }) => {
          if (loading) {
            return <Loader />;
          }

          const account = {
            account: data.account,
            timezone: data.timezone
          };

          return (
            <PageHeading title={account.account.name}>
              <TextNavigation
                activeColor={colors.primary}
                activeItem={activeItem}
                inactiveColor={colors.black}
                items={getNavItems(account.account.accountType)}
                onItemClicked={this.setActiveMenuItem}
              />
              <div>{this.renderActiveTab(account)}</div>
            </PageHeading>
          );
        }}
      </Query>
    );
  }
}

const getNavItems = (accountType: AccountType) => {
  switch (accountType) {
    case AccountType.PWS:
      return pwsNavItems;
    case AccountType.Hydra:
      return hydraNavItems;
    default:
      return facilityNavItems;
  }
};

const pwsNavItems = [
  {
    name: 'Details'
  },
  {
    name: 'Reporting'
  },
  {
    name: 'Users'
  },
  {
    name: 'Invites'
  },
  {
    name: 'Settings'
  },
  {
    name: 'Analytes'
  },
  {
    name: 'Packages'
  },
  {
    name: 'SKUs'
  },
  {
    name: 'EDD'
  },
  {
    name: 'Custom Fields'
  },
  {
    name: 'Tools'
  }
];

const facilityNavItems = [
  {
    name: 'Details'
  },
  {
    name: 'Reporting'
  },
  {
    name: 'Users'
  },
  {
    name: 'Invites'
  },
  {
    name: 'Settings'
  },
  {
    name: 'Packages'
  },
  {
    name: 'SKUs'
  },
  {
    name: 'EDD'
  }
];

const hydraNavItems = [
  {
    name: 'Details'
  },
  {
    name: 'Child Accounts'
  },
  {
    name: 'Users'
  },
  {
    name: 'Invites'
  },
  {
    name: 'Settings'
  },
  {
    name: 'Packages'
  }
];

export default AccountDetailsContainer;
